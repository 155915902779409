export default {
  countries: {
    Aruba: "ABW",
    Afghanistan: "AFG",
    Angola: "AGO",
    Anguilla: "AIA",
    "Åland Islands": "ALA",
    Albania: "ALB",
    Andorra: "AND",
    "United Arab Emirates": "ARE",
    Argentina: "ARG",
    Armenia: "ARM",
    "American Samoa": "ASM",
    Antarctica: "ATA",
    "French Southern Territories": "ATF",
    "Antigua and Barbuda": "ATG",
    Australia: "AUS",
    Austria: "AUT",
    Azerbaijan: "AZE",
    Burundi: "BDI",
    Belgium: "BEL",
    Benin: "BEN",
    "Bonaire, Sint Eustatius and Saba": "BES",
    "Burkina Faso": "BFA",
    Bangladesh: "BGD",
    Bulgaria: "BGR",
    Bahrain: "BHR",
    Bahamas: "BHS",
    "Bosnia and Herzegovina": "BIH",
    "Saint Barthélemy": "BLM",
    Belarus: "BLR",
    Belize: "BLZ",
    Bermuda: "BMU",
    "Bolivia (Plurinational State of)": "BOL",
    Brazil: "BRA",
    Barbados: "BRB",
    "Brunei Darussalam": "BRN",
    Bhutan: "BTN",
    "Bouvet Island": "BVT",
    Botswana: "BWA",
    "Central African Republic": "CAF",
    Canada: "CAN",
    "Cocos (Keeling) Islands": "CCK",
    Switzerland: "CHE",
    Chile: "CHL",
    China: "CHN",
    "Côte d'Ivoire": "CIV",
    Cameroon: "CMR",
    "Congo, Democratic Republic of the": "COD",
    Congo: "COG",
    "Cook Islands": "COK",
    Colombia: "COL",
    Comoros: "COM",
    "Cabo Verde": "CPV",
    "Costa Rica": "CRI",
    Cuba: "CUB",
    Curaçao: "CUW",
    "Christmas Island": "CXR",
    "Cayman Islands": "CYM",
    Cyprus: "CYP",
    Czechia: "CZE",
    Germany: "DEU",
    Djibouti: "DJI",
    Dominica: "DMA",
    Denmark: "DNK",
    "Dominican Republic": "DOM",
    Algeria: "DZA",
    Ecuador: "ECU",
    Egypt: "EGY",
    Eritrea: "ERI",
    "Western Sahara": "ESH",
    Spain: "ESP",
    Estonia: "EST",
    Ethiopia: "ETH",
    Finland: "FIN",
    Fiji: "FJI",
    "Falkland Islands": "FLK",
    France: "FRA",
    "Faroe Islands": "FRO",
    Micronesia: "FSM",
    Gabon: "GAB",
    "United Kingdom of Great Britain and Northern Ireland": "GBR",
    Georgia: "GEO",
    Guernsey: "GGY",
    Ghana: "GHA",
    Gibraltar: "GIB",
    Guinea: "GIN",
    Guadeloupe: "GLP",
    Gambia: "GMB",
    "Guinea-Bissau": "GNB",
    "Equatorial Guinea": "GNQ",
    Greece: "GRC",
    Grenada: "GRD",
    Greenland: "GRL",
    Guatemala: "GTM",
    "French Guiana": "GUF",
    Guam: "GUM",
    Guyana: "GUY",
    "Hong Kong": "HKG",
    "Heard Island and McDonald Islands": "HMD",
    Honduras: "HND",
    Croatia: "HRV",
    Haiti: "HTI",
    Hungary: "HUN",
    Indonesia: "IDN",
    "Isle of Man": "IMN",
    India: "IND",
    "British Indian Ocean Territory": "IOT",
    Ireland: "IRL",
    Iran: "IRN",
    Iraq: "IRQ",
    Iceland: "ISL",
    Israel: "ISR",
    Italy: "ITA",
    Jamaica: "JAM",
    Jersey: "JEY",
    Jordan: "JOR",
    Japan: "JPN",
    Kazakhstan: "KAZ",
    Kenya: "KEN",
    Kyrgyzstan: "KGZ",
    Cambodia: "KHM",
    Kiribati: "KIR",
    "Saint Kitts and Nevis": "KNA",
    "Korea, Republic of": "KOR",
    Kuwait: "KWT",
    "Lao People's Democratic Republic": "LAO",
    Lebanon: "LBN",
    Liberia: "LBR",
    Libya: "LBY",
    "Saint Lucia": "LCA",
    Liechtenstein: "LIE",
    "Sri Lanka": "LKA",
    Lesotho: "LSO",
    Lithuania: "LTU",
    Luxembourg: "LUX",
    Latvia: "LVA",
    Macao: "MAC",
    "Saint Martin": "MAF",
    Morocco: "MAR",
    Monaco: "MCO",
    "Moldova, Republic of": "MDA",
    Madagascar: "MDG",
    Maldives: "MDV",
    Mexico: "MEX",
    "Marshall Islands": "MHL",
    "North Macedonia": "MKD",
    Mali: "MLI",
    Malta: "MLT",
    Myanmar: "MMR",
    Montenegro: "MNE",
    Mongolia: "MNG",
    "Northern Mariana Islands": "MNP",
    Mozambique: "MOZ",
    Mauritania: "MRT",
    Montserrat: "MSR",
    Martinique: "MTQ",
    Mauritius: "MUS",
    Malawi: "MWI",
    Malaysia: "MYS",
    Mayotte: "MYT",
    Namibia: "NAM",
    "New Caledonia": "NCL",
    Niger: "NER",
    "Norfolk Island": "NFK",
    Nigeria: "NGA",
    Nicaragua: "NIC",
    Niue: "NIU",
    Netherlands: "NLD",
    Norway: "NOR",
    Nepal: "NPL",
    Nauru: "NRU",
    "New Zealand": "NZL",
    Oman: "OMN",
    Pakistan: "PAK",
    Panama: "PAN",
    Pitcairn: "PCN",
    Peru: "PER",
    Philippines: "PHL",
    Palau: "PLW",
    "Papua New Guinea": "PNG",
    Poland: "POL",
    "Puerto Rico": "PRI",
    "Korea (Democratic People's Republic of)": "PRK",
    Portugal: "PRT",
    Paraguay: "PRY",
    "Palestine, State of": "PSE",
    "French Polynesia": "PYF",
    Qatar: "QAT",
    Réunion: "REU",
    Romania: "ROU",
    "Russian Federation": "RUS",
    Rwanda: "RWA",
    "Saudi Arabia": "SAU",
    Sudan: "SDN",
    Senegal: "SEN",
    Singapore: "SGP",
    "South Georgia and the South Sandwich Islands": "SGS",
    "Saint Helena, Ascension and Tristan da Cunha": "SHN",
    "Svalbard and Jan Mayen": "SJM",
    "Solomon Islands": "SLB",
    "Sierra Leone": "SLE",
    "El Salvador": "SLV",
    "San Marino": "SMR",
    Somalia: "SOM",
    "Saint Pierre and Miquelon": "SPM",
    Serbia: "SRB",
    "South Sudan": "SSD",
    "Sao Tome and Principe": "STP",
    Suriname: "SUR",
    Slovakia: "SVK",
    Slovenia: "SVN",
    Sweden: "SWE",
    Eswatini: "SWZ",
    "Sint Maarten": "SXM",
    Seychelles: "SYC",
    "Syrian Arab Republic": "SYR",
    "Turks and Caicos Islands": "TCA",
    Chad: "TCD",
    Togo: "TGO",
    Thailand: "THA",
    Tajikistan: "TJK",
    Tokelau: "TKL",
    Turkmenistan: "TKM",
    "Timor-Leste": "TLS",
    Tonga: "TON",
    "Trinidad and Tobago": "TTO",
    Tunisia: "TUN",
    Turkey: "TUR",
    Tuvalu: "TUV",
    Taiwan: "TWN",
    Tanzania: "TZA",
    Uganda: "UGA",
    Ukraine: "UKR",
    "United States Minor Outlying Islands": "UMI",
    Uruguay: "URY",
    "United States of America": "USA",
    Uzbekistan: "UZB",
    "Holy See": "VAT",
    "Saint Vincent and the Grenadines": "VCT",
    Venezuela: "VEN",
    "Virgin Islands (British)": "VGB",
    "Virgin Islands (U.S.)": "VIR",
    "Viet Nam": "VNM",
    Vanuatu: "VUT",
    "Wallis and Futuna": "WLF",
    Samoa: "WSM",
    Yemen: "YEM",
    "South Africa": "ZAF",
    Zambia: "ZMB",
    Zimbabwe: "ZWE",
  },
  countryList: [
    { name: "Aruba", value: "ABW" },
    { name: "Afghanistan", value: "AFG" },
    { name: "Angola", value: "AGO" },
    { name: "Anguilla", value: "AIA" },
    { name: "Åland Islands", value: "ALA" },
    { name: "Albania", value: "ALB" },
    { name: "Andorra", value: "AND" },
    { name: "United Arab Emirates", value: "ARE" },
    { name: "Argentina", value: "ARG" },
    { name: "Armenia", value: "ARM" },
    { name: "American Samoa", value: "ASM" },
    { name: "Antarctica", value: "ATA" },
    { name: "French Southern Territories", value: "ATF" },
    { name: "Antigua and Barbuda", value: "ATG" },
    { name: "Australia", value: "AUS" },
    { name: "Austria", value: "AUT" },
    { name: "Azerbaijan", value: "AZE" },
    { name: "Burundi", value: "BDI" },
    { name: "Belgium", value: "BEL" },
    { name: "Benin", value: "BEN" },
    { name: "Bonaire, Sint Eustatius and Saba", value: "BES" },
    { name: "Burkina Faso", value: "BFA" },
    { name: "Bangladesh", value: "BGD" },
    { name: "Bulgaria", value: "BGR" },
    { name: "Bahrain", value: "BHR" },
    { name: "Bahamas", value: "BHS" },
    { name: "Bosnia and Herzegovina", value: "BIH" },
    { name: "Saint Barthélemy", value: "BLM" },
    { name: "Belarus", value: "BLR" },
    { name: "Belize", value: "BLZ" },
    { name: "Bermuda", value: "BMU" },
    { name: "Bolivia (Plurinational State of)", value: "BOL" },
    { name: "Brazil", value: "BRA" },
    { name: "Barbados", value: "BRB" },
    { name: "Brunei Darussalam", value: "BRN" },
    { name: "Bhutan", value: "BTN" },
    { name: "Bouvet Island", value: "BVT" },
    { name: "Botswana", value: "BWA" },
    { name: "Central African Republic", value: "CAF" },
    { name: "Canada", value: "CAN" },
    { name: "Cocos (Keeling) Islands", value: "CCK" },
    { name: "Switzerland", value: "CHE" },
    { name: "Chile", value: "CHL" },
    { name: "China", value: "CHN" },
    { name: "Côte d'Ivoire", value: "CIV" },
    { name: "Cameroon", value: "CMR" },
    { name: "Congo, Democratic Republic of the", value: "COD" },
    { name: "Congo", value: "COG" },
    { name: "Cook Islands", value: "COK" },
    { name: "Colombia", value: "COL" },
    { name: "Comoros", value: "COM" },
    { name: "Cabo Verde", value: "CPV" },
    { name: "Costa Rica", value: "CRI" },
    { name: "Cuba", value: "CUB" },
    { name: "Curaçao", value: "CUW" },
    { name: "Christmas Island", value: "CXR" },
    { name: "Cayman Islands", value: "CYM" },
    { name: "Cyprus", value: "CYP" },
    { name: "Czechia", value: "CZE" },
    { name: "Germany", value: "DEU" },
    { name: "Djibouti", value: "DJI" },
    { name: "Dominica", value: "DMA" },
    { name: "Denmark", value: "DNK" },
    { name: "Dominican Republic", value: "DOM" },
    { name: "Algeria", value: "DZA" },
    { name: "Ecuador", value: "ECU" },
    { name: "Egypt", value: "EGY" },
    { name: "Eritrea", value: "ERI" },
    { name: "Western Sahara", value: "ESH" },
    { name: "Spain", value: "ESP" },
    { name: "Estonia", value: "EST" },
    { name: "Ethiopia", value: "ETH" },
    { name: "Finland", value: "FIN" },
    { name: "Fiji", value: "FJI" },
    { name: "Falkland Islands", value: "FLK" },
    { name: "France", value: "FRA" },
    { name: "Faroe Islands", value: "FRO" },
    { name: "Micronesia", value: "FSM" },
    { name: "Gabon", value: "GAB" },
    {
      name: "United Kingdom of Great Britain and Northern Ireland",
      value: "GBR",
    },
    { name: "Georgia", value: "GEO" },
    { name: "Guernsey", value: "GGY" },
    { name: "Ghana", value: "GHA" },
    { name: "Gibraltar", value: "GIB" },
    { name: "Guinea", value: "GIN" },
    { name: "Guadeloupe", value: "GLP" },
    { name: "Gambia", value: "GMB" },
    { name: "Guinea-Bissau", value: "GNB" },
    { name: "Equatorial Guinea", value: "GNQ" },
    { name: "Greece", value: "GRC" },
    { name: "Grenada", value: "GRD" },
    { name: "Greenland", value: "GRL" },
    { name: "Guatemala", value: "GTM" },
    { name: "French Guiana", value: "GUF" },
    { name: "Guam", value: "GUM" },
    { name: "Guyana", value: "GUY" },
    { name: "Hong Kong", value: "HKG" },
    { name: "Heard Island and McDonald Islands", value: "HMD" },
    { name: "Honduras", value: "HND" },
    { name: "Croatia", value: "HRV" },
    { name: "Haiti", value: "HTI" },
    { name: "Hungary", value: "HUN" },
    { name: "Indonesia", value: "IDN" },
    { name: "Isle of Man", value: "IMN" },
    { name: "India", value: "IND" },
    { name: "British Indian Ocean Territory", value: "IOT" },
    { name: "Ireland", value: "IRL" },
    { name: "Iran", value: "IRN" },
    { name: "Iraq", value: "IRQ" },
    { name: "Iceland", value: "ISL" },
    { name: "Israel", value: "ISR" },
    { name: "Italy", value: "ITA" },
    { name: "Jamaica", value: "JAM" },
    { name: "Jersey", value: "JEY" },
    { name: "Jordan", value: "JOR" },
    { name: "Japan", value: "JPN" },
    { name: "Kazakhstan", value: "KAZ" },
    { name: "Kenya", value: "KEN" },
    { name: "Kyrgyzstan", value: "KGZ" },
    { name: "Cambodia", value: "KHM" },
    { name: "Kiribati", value: "KIR" },
    { name: "Saint Kitts and Nevis", value: "KNA" },
    { name: "Korea, Republic of", value: "KOR" },
    { name: "Kuwait", value: "KWT" },
    { name: "Lao People's Democratic Republic", value: "LAO" },
    { name: "Lebanon", value: "LBN" },
    { name: "Liberia", value: "LBR" },
    { name: "Libya", value: "LBY" },
    { name: "Saint Lucia", value: "LCA" },
    { name: "Liechtenstein", value: "LIE" },
    { name: "Sri Lanka", value: "LKA" },
    { name: "Lesotho", value: "LSO" },
    { name: "Lithuania", value: "LTU" },
    { name: "Luxembourg", value: "LUX" },
    { name: "Latvia", value: "LVA" },
    { name: "Macao", value: "MAC" },
    { name: "Saint Martin", value: "MAF" },
    { name: "Morocco", value: "MAR" },
    { name: "Monaco", value: "MCO" },
    { name: "Moldova, Republic of", value: "MDA" },
    { name: "Madagascar", value: "MDG" },
    { name: "Maldives", value: "MDV" },
    { name: "Mexico", value: "MEX" },
    { name: "Marshall Islands", value: "MHL" },
    { name: "North Macedonia", value: "MKD" },
    { name: "Mali", value: "MLI" },
    { name: "Malta", value: "MLT" },
    { name: "Myanmar", value: "MMR" },
    { name: "Montenegro", value: "MNE" },
    { name: "Mongolia", value: "MNG" },
    { name: "Northern Mariana Islands", value: "MNP" },
    { name: "Mozambique", value: "MOZ" },
    { name: "Mauritania", value: "MRT" },
    { name: "Montserrat", value: "MSR" },
    { name: "Martinique", value: "MTQ" },
    { name: "Mauritius", value: "MUS" },
    { name: "Malawi", value: "MWI" },
    { name: "Malaysia", value: "MYS" },
    { name: "Mayotte", value: "MYT" },
    { name: "Namibia", value: "NAM" },
    { name: "New Caledonia", value: "NCL" },
    { name: "Niger", value: "NER" },
    { name: "Norfolk Island", value: "NFK" },
    { name: "Nigeria", value: "NGA" },
    { name: "Nicaragua", value: "NIC" },
    { name: "Niue", value: "NIU" },
    { name: "Netherlands", value: "NLD" },
    { name: "Norway", value: "NOR" },
    { name: "Nepal", value: "NPL" },
    { name: "Nauru", value: "NRU" },
    { name: "New Zealand", value: "NZL" },
    { name: "Oman", value: "OMN" },
    { name: "Pakistan", value: "PAK" },
    { name: "Panama", value: "PAN" },
    { name: "Pitcairn", value: "PCN" },
    { name: "Peru", value: "PER" },
    { name: "Philippines", value: "PHL" },
    { name: "Palau", value: "PLW" },
    { name: "Papua New Guinea", value: "PNG" },
    { name: "Poland", value: "POL" },
    { name: "Puerto Rico", value: "PRI" },
    { name: "Korea (Democratic People's Republic of)", value: "PRK" },
    { name: "Portugal", value: "PRT" },
    { name: "Paraguay", value: "PRY" },
    { name: "Palestine, State of", value: "PSE" },
    { name: "French Polynesia", value: "PYF" },
    { name: "Qatar", value: "QAT" },
    { name: "Réunion", value: "REU" },
    { name: "Romania", value: "ROU" },
    { name: "Russian Federation", value: "RUS" },
    { name: "Rwanda", value: "RWA" },
    { name: "Saudi Arabia", value: "SAU" },
    { name: "Sudan", value: "SDN" },
    { name: "Senegal", value: "SEN" },
    { name: "Singapore", value: "SGP" },
    { name: "South Georgia and the South Sandwich Islands", value: "SGS" },
    { name: "Saint Helena, Ascension and Tristan da Cunha", value: "SHN" },
    { name: "Svalbard and Jan Mayen", value: "SJM" },
    { name: "Solomon Islands", value: "SLB" },
    { name: "Sierra Leone", value: "SLE" },
    { name: "El Salvador", value: "SLV" },
    { name: "San Marino", value: "SMR" },
    { name: "Somalia", value: "SOM" },
    { name: "Saint Pierre and Miquelon", value: "SPM" },
    { name: "Serbia", value: "SRB" },
    { name: "South Sudan", value: "SSD" },
    { name: "Sao Tome and Principe", value: "STP" },
    { name: "Suriname", value: "SUR" },
    { name: "Slovakia", value: "SVK" },
    { name: "Slovenia", value: "SVN" },
    { name: "Sweden", value: "SWE" },
    { name: "Eswatini", value: "SWZ" },
    { name: "Sint Maarten", value: "SXM" },
    { name: "Seychelles", value: "SYC" },
    { name: "Syrian Arab Republic", value: "SYR" },
    { name: "Turks and Caicos Islands", value: "TCA" },
    { name: "Chad", value: "TCD" },
    { name: "Togo", value: "TGO" },
    { name: "Thailand", value: "THA" },
    { name: "Tajikistan", value: "TJK" },
    { name: "Tokelau", value: "TKL" },
    { name: "Turkmenistan", value: "TKM" },
    { name: "Timor-Leste", value: "TLS" },
    { name: "Tonga", value: "TON" },
    { name: "Trinidad and Tobago", value: "TTO" },
    { name: "Tunisia", value: "TUN" },
    { name: "Turkey", value: "TUR" },
    { name: "Tuvalu", value: "TUV" },
    { name: "Taiwan", value: "TWN" },
    { name: "Tanzania", value: "TZA" },
    { name: "Uganda", value: "UGA" },
    { name: "Ukraine", value: "UKR" },
    { name: "United States Minor Outlying Islands", value: "UMI" },
    { name: "Uruguay", value: "URY" },
    { name: "United States of America", value: "USA" },
    { name: "Uzbekistan", value: "UZB" },
    { name: "Holy See", value: "VAT" },
    { name: "Saint Vincent and the Grenadines", value: "VCT" },
    { name: "Venezuela", value: "VEN" },
    { name: "Virgin Islands (British)", value: "VGB" },
    { name: "Virgin Islands (U.S.)", value: "VIR" },
    { name: "Viet Nam", value: "VNM" },
    { name: "Vanuatu", value: "VUT" },
    { name: "Wallis and Futuna", value: "WLF" },
    { name: "Samoa", value: "WSM" },
    { name: "Yemen", value: "YEM" },
    { name: "South Africa", value: "ZAF" },
    { name: "Zambia", value: "ZMB" },
    { name: "Zimbabwe", value: "ZWE" },
  ],
};
